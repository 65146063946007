import { Routes, Route } from "react-router-dom";
import './App.css';
import Index from './Components/Index/Index';
import Governance from "./Components/Governance/Governance";
import Transparency from "./Components/Transparency/Transparency";

function App() {
  return (
    <div className="App">
<Routes>
<Route path="/" element={<Index/>} />
<Route path="/governance" element={<Governance/>} />
{/* <Route path="/transparency" element={<Transparency/>} /> */}

</Routes>
    </div>
  );
}

export default App;
