import React from "react";

export default function Blockchain() {
  return (
    <div>
      <div
        className="feature-area-2 pt-110 pb-140 position-relative overflow-hidden"
        style={{
          backgroundImage: "url(assets/img/feature-area-bg.png)",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        id="blockchain"
      >
        <div className="feature-area-shape">
          <img
            className="feature-shape2-1 alltuchtopdown"
            src="assets/img/circle_bg.png"
            alt="img"
          />
          <img
            className="feature-shape2-2 alltuchtopdown"
            src="assets/img/shape.png"
            alt="img"
          />
          <img
            className="feature-shape2-3 leftToRight"
            src="assets/img/mail.png"
            alt="img"
          />
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-5 col-lg-8">
              <div className="section-title text-center mb-50">
                {/* <span className="sub-title">ABOUT BLOCKCHAIN</span> */}
                <h2 className="title style2">Onmax Offers </h2>
                {/* <p className="sec-text">
                  We’ve worked with over 400 companies to build blockchain
                  solutions for their business, and we are still growing.
                </p> */}
              </div>
            </div>
          </div>
          <div className="feature-grid-wrap">
            <div className="feature-card-grid text-start">
              <div className="feature-card-details">
                <h3 className="feature-card-title">Flexibility </h3>
                <p className="feature-card-text">
                  Users are offered flexibility in Farming programs on the basis
                  of short term of long term investment options.
                </p>
                <div className="checklist">
                  {/* <ul>
                    <li>
                      <i className="fas fa-circle" /> Blockchain solutions for
                      their business.
                    </li>
                    <li>
                      <i className="fas fa-circle" /> Blockchain solutions for
                      their business.
                    </li>
                    <li>
                      <i className="fas fa-circle" /> Blockchain solutions for
                      their business.
                    </li>
                  </ul> */}
                </div>
              </div>
              <div className="feature-card-img">
                <img
                  className="alltuchtopdown"
                  src="assets/img/flexibility.png"
                  alt=" Onmax Flexibility"
                />
              </div>
            </div>
            <div className="feature-card-grid text-start">
              <div className="feature-card-details">
                <h3 className="feature-card-title">Transparency</h3>
                <p className="feature-card-text">
                  Funds within the Farming contract are transparently stored in
                  a treasury wallet which can be seen by anyone on blockchain.
                </p>
                {/* <p className="feature-card-text">
                  We’ve worked with over 400 companies to build
                </p> */}
              </div>
              <div className="feature-card-img">
                <img
                  src="assets/img/transparency.png"
                  alt=" Onmax Transparency"
                />
              </div>
            </div>
            <div className="feature-card-grid text-start">
              <div className="feature-card-details">
                <h3 className="feature-card-title">Safety & Security</h3>
                <p className="feature-card-text">
                  Onmax Farming contracts are thoroughly audited by industry’s
                  leading security auditors & security advisors.
                </p>
              </div>
              <div className="feature-card-img">
                <img
                  src="assets/img/safety_security.png"
                  alt="Onmax Safety & Security"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
