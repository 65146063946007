import React from "react";

export default function Introducing() {
  return (
    <div>
      <div className="pt-130 pb-100 overflow-hidden bg-black2"  id="about">
        <div className="container text-start">
          <div className="row ">
            <div className="col-xl-6 ">
              <div className="section-title mb-45 ">
                <h2 className="title style2">How Onmax Works</h2>
                <p className="sec-text">
                  Onmax turns farming deposits into liquidity & generates{" "}
                  <br /> yields in live markets.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-between">
            <div className="col-xl-4">
              <div className="intro-wrap mt-xl-4">
                <h5 className="">1. Farming Deposits </h5>
                <p className="intro-wrap-text">
                  Users Farm their assets on Onmax while selecting their
                  preferred unfarming method, either for fixed or flexible
                  period of time.{" "}
                </p>
              </div>
              <div className="intro-wrap">
                <h5 className="">2. Liquidity Injection </h5>
                <p className="intro-wrap-text">
                  Assets are combined in the Onmax Pool which can be used to
                  access instant liquidity for executing swaps, flashloans &
                  other trades.{" "}
                </p>
              </div>
              <div className="intro-wrap">
                <h5 className="">3. Yield Farming & Liquidity Mining </h5>
                <p className="intro-wrap-text">
                  Onmax collects yields by offering liquidity utilization to
                  market players while adding it back to the Pool for
                  compounding.{" "}
                </p>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="intro-thumb1 alltuchtopdown">
                <img src="assets/img/how-onmax-works.png" alt="How Onmax Works" />
              </div>
              <div className="intro-wrap mt-50">
                <h5 className="">4. Reward Distribution </h5>
                <p className="intro-wrap-text">
                  On maturity, farming collateral is unlocked with equivalent
                  rewards generated by the overall utility of the underlying
                  Farming module.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
