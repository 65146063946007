import React from "react";

export default function FAQmain() {
  return (
    <div>
      <div className="pt-100 pb-140 overflow-hidden">
        <div className="container">
          <div className="row gy-40 justify-content-between align-items-center">
            <div className="col-xl-4 text-xl-start">
              <div className="section-title mb-50">
                <h2 className="title style2">FAQs</h2>
                {/* <p className="sec-text">
                  DO have any kind Of questions? We're here to help.
                </p> */}
              </div>
              <div className="faq-thumb mt-60">
                <img src="assets/img/faqs.png" alt="Onmax FAQs" />
              </div>
            </div>
            <div className="col-xxl-6 col-xl-8 text-start">
              <div className="accordion-area accordion" id="faqAccordion">
                <div className="accordion-card active">
                  <div className="accordion-header" id="collapse-item-1">
                    <button
                      className="accordion-button "
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse-1"
                      aria-expanded="true"
                      aria-controls="collapse-1"
                    >
                      <span className="number">1</span> How does Onmax Farming
                      works?
                    </button>
                  </div>
                  <div
                    id="collapse-1"
                    className="accordion-collapse collapse show"
                    aria-labelledby="collapse-item-1"
                    data-bs-parent="#faqAccordion"
                  >
                    <div className="accordion-body">
                      <p className="faq-text">
                        Users can Farm their assets at Onmax & earn yields as
                        they are utilized in markets.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-card">
                  <div className="accordion-header" id="collapse-item-2">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse-2"
                      aria-expanded="false"
                      aria-controls="collapse-2"
                    >
                      <span className="number">2</span>Where farmed assets are
                      used?
                    </button>
                  </div>
                  <div
                    id="collapse-2"
                    className="accordion-collapse collapse "
                    aria-labelledby="collapse-item-2"
                    data-bs-parent="#faqAccordion"
                  >
                    <div className="accordion-body">
                      <p className="faq-text">
                        All the farmed assets are sent to Onmax Farming Pool
                        which allows liquidity access to market players for
                        flashloans, instant trades & swaps.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-card">
                  <div className="accordion-header" id="collapse-item-3">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse-3"
                      aria-expanded="false"
                      aria-controls="collapse-3"
                    >
                      <span className="number">3</span>How is the yield
                      generated?
                    </button>
                  </div>
                  <div
                    id="collapse-3"
                    className="accordion-collapse collapse "
                    aria-labelledby="collapse-item-3"
                    data-bs-parent="#faqAccordion"
                  >
                    <div className="accordion-body">
                      <p className="faq-text">
                        On the utilization of assets, a small portion of
                        transaction fee + 2% standard fee is charged which goes
                        to users.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-card">
                  <div className="accordion-header" id="collapse-item-4">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse-4"
                      aria-expanded="false"
                      aria-controls="collapse-4"
                    >
                      <span className="number">4</span> How do users claim
                      rewards?
                    </button>
                  </div>
                  <div
                    id="collapse-4"
                    className="accordion-collapse collapse "
                    aria-labelledby="collapse-item-4"
                    data-bs-parent="#faqAccordion"
                  >
                    <div className="accordion-body">
                      <p className="faq-text">
                        Users will be able to claim their rewards from the
                        Farming dashboard.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-card">
                  <div className="accordion-header" id="collapse-item-5">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse-5"
                      aria-expanded="false"
                      aria-controls="collapse-5"
                    >
                      <span className="number">5</span> What is the yield range
                      for assets?
                    </button>
                  </div>
                  <div
                    id="collapse-5"
                    className="accordion-collapse collapse "
                    aria-labelledby="collapse-item-5"
                    data-bs-parent="#faqAccordion"
                  >
                    <div className="accordion-body">
                      <p className="faq-text">
                        Yield ranges from 9% to 28% in accordance with the
                        utility and available liquidity of the asset.
                      </p>
                    </div>
                  </div>
                </div>
                {/* <div className="accordion-card">
                  <div className="accordion-header" id="collapse-item-6">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse-6"
                      aria-expanded="false"
                      aria-controls="collapse-6"
                    >
                      <span className="number">6</span> How to buy FOX tokens?
                    </button>
                  </div>
                  <div
                    id="collapse-6"
                    className="accordion-collapse collapse "
                    aria-labelledby="collapse-item-6"
                    data-bs-parent="#faqAccordion"
                  >
                    <div className="accordion-body">
                      <p className="faq-text">
                        It's very simple! Register here. In your personal
                        account, create a wallet where you can store your FOX
                        tokens. Then just send any amount to the displayed
                        address in your office.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-card">
                  <div className="accordion-header" id="collapse-item-7">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse-7"
                      aria-expanded="false"
                      aria-controls="collapse-7"
                    >
                      <span className="number">7</span> How to buy FOX tokens?
                    </button>
                  </div>
                  <div
                    id="collapse-7"
                    className="accordion-collapse collapse "
                    aria-labelledby="collapse-item-7"
                    data-bs-parent="#faqAccordion"
                  >
                    <div className="accordion-body">
                      <p className="faq-text">
                        It's very simple! Register here. In your personal
                        account, create a wallet where you can store your FOX
                        tokens. Then just send any amount to the displayed
                        address in your office.
                      </p>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
