import React from "react";

export default function Business() {
  return (
    <div>
   <div className="pb-120 position-relative z-index-common">
  <div className="container">
    <div className="cta-wrap3 text-start">
      <div className="cta-wrap-details">
        <div className="section-title mb-20">
          <h3 className="title style2 text-title">Unlock <s>passive</s> massive rewards
          </h3>
          <p className="sec-text text-title">Why Farm for minimal rewards when you can earn up to 28% APY at Onmax! 
          </p>
        </div>
      
        <a  href="http://app.onmaxyield.farm/" target='_blank' className="btn btn-two">      Farm Now</a>

      </div>
      <div className="cta-3-thumb movingX alltuchtopdown">
        <img src="assets/img/unlock-passive-massive-rewards.png" alt="Onmax Rewards" />
      </div>
    </div>
  </div>
</div>

    </div>
  );
}
