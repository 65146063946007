import React from "react";

export default function Footer() {
    const year = new Date().getFullYear();

  return (
    <div>
      <footer className="footer-wrapper footer-layout1 position-relative">
        <div className="bg-gradient-1">
          <img src="assets/img/update/bg/bg-gradient1-1.jpg" alt="img" />
        </div>
        <div className="container">
          <div className="footer-menu-area">
            <div className="row gy-4 justify-content-between align-items-center">
              <div className="col-xl-5 col-lg-4">
                {/* <div className="social-btn justify-content-center justify-content-lg-start">
                  <a href="https://www.facebook.com/">
                    <i className="fab fa-facebook-f" />
                  </a>
                  <a href="#">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M10.0596 7.34522L15.8879 0.570312H14.5068L9.44607 6.45287L5.40411 0.570312H0.742188L6.85442 9.46578L0.742188 16.5703H2.12338L7.4676 10.3581L11.7362 16.5703H16.3981L10.0593 7.34522H10.0596ZM8.16787 9.54415L7.54857 8.65836L2.62104 1.61005H4.74248L8.71905 7.29827L9.33834 8.18405L14.5074 15.5779H12.386L8.16787 9.54449V9.54415Z"
                        fill="currentColor"
                      />
                    </svg>
                  </a>
                  <a href="https://instagram.com/">
                    <i className="fab fa-instagram" />
                  </a>
                  <a href="https://linkedin.com/">
                    <i className="fab fa-linkedin" />
                  </a>
                </div> */}
              </div>
              {/* <div className="col-xl-7 col-lg-8 text-lg-end text-center">
                <ul className="footer-menu-list">
                  <li>
                    <a href="index.html">HOME</a>
                  </li>
                  <li>
                    <a href="#">OUR PROJECTS</a>
                  </li>
                  <li>
                    <a href="#">OUR TEAM</a>
                  </li>
                  <li>
                    <a href="#">NEWS FAQ</a>
                  </li>
                  <li>
                    <a href="#">DOCUMENTS</a>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
        <div className="copyright-wrap text-center text-lg-start">
          <div className="container">
            <div className="row gy-3 justify-content-between align-items-center">
              <div className="col-lg-6 align-self-center">
                <p className="copyright-text cursor-pointer">
                  Copyright © <span>{year}</span> ONMAX All rights reserved.
                </p>
              </div>
              <div className="col-lg-6 text-lg-end">
                <ul className="footer-links">
                  
                  <li>
                    <a href="https://onmax.gitbook.io/docs-onmax/protocol/terms-of-use" target="_blank">Terms of Use</a>
                  </li>
                  <li>
                    <a href="https://onmax.gitbook.io/docs-onmax/protocol/media-kit" target="_blank">Media Kit</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
