import React from "react";

export default function Press() {
  return (
    <div>
      <div className="press-cta-area pt-130">
        <div className="container">
          <div className="section-title text-center mb-50">
            <h2 className="title style2">Entities We Serves</h2>
          </div>
          <div className="row text-start">
            <div className="col-lg-6 mt-2">
              <div className="cta-wrap1 he-we">
                <div className="cta-wrap-details">
                  <h3 className="cta-wrap-title m-0 mb-4">Farmers </h3>
                  <p className="cta-wrap-text">
                    Farmers who want to multiply their crypto holdings using a
                    safe, secure & low-risk strategy.
                  </p>
                </div>
                <a className="btn btn4" href="#" tabIndex={0}>
                  Farm Now{" "}
                </a>
              </div>
            </div>
            <div className="col-lg-6 mt-2">
              <div className="cta-wrap1 he-we">
                <div className="cta-wrap-details">
                  <h3 className="cta-wrap-title m-0 mb-4">Clients </h3>

                  <p className="cta-wrap-text">
                    Business clients who want to access liquidity from the
                    Farming Pool for various purposes.{" "}
                  </p>
                </div>
                <a className="btn btn4" href="#" tabIndex={0}>
                  {" "}
                  Access Liquidity
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
