import React from "react";
import Hero from "./Hero";
import Header from "../Coman/Header";
import Main from "./Main";
import Introducing from "./Introducing";
import Roadmap from "./Roadmap";
import FAQmain from "./FAQmain";
import Footer from "../Coman/Footer";
import Blockchain from "./Blockchain";
import Business from "./Business";
import Press from "./Press";

export default function Index() {
  return (
    <div>
      <div className="home-purple-gradient">
        <Header />
        <Hero />
        <Main />
        <Introducing />
        <Roadmap />
        <Press />
        <Blockchain />
        <FAQmain />
        <Business />
        <Footer />
      </div>
    </div>
  );
}
