import React from "react";
import Slider from "react-slick";

export default function Roadmap() {
  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <div
        className="pt-130 pb-140 overflow-hidden bg-black2 position-relative z-index-common"
        id="roadMap"
      >
        <div className="bg-gradient-2">
          <img src="assets/img/update/bg/bg-gradient1-1.jpg" alt="img" />
        </div>
        <div className="container text-start">
          <div className="row justify-content-between">
            <div className="col-lg-6 col-sm-8">
              <div className="section-title mb-50">
                <h2 className="title style2">Supported Assets</h2>
              </div>
            </div>
            {/* <div className="col-sm-auto">
        <div className="icon-box">
          <button className="slider-arrow prev-btn default"><i className="fas fa-arrow-left" /></button>
          <button className="slider-arrow next-btn default"><i className="fas fa-arrow-right" /></button>
        </div>
      </div> */}
          </div>
        </div>
        <div className="container-fluid p-0">
          <div className="slider-area position-relative">
            <div className="row roadMap-active2 roadmap-slider1">
              {/* <div className="slider-container text-start"> */}
              <Slider {...settings}>
                <div className="text-start">
                  <div className="roadmap-item mx-2">
                    <div className="roadmap-content">
                      <h4 className="title d-flex align-items-center">
                        <img src="assets/img/eth.png" alt="icon" height={50} />{" "}
                        ETH
                      </h4>
                      <div className="d-flex justify-content-between">
                        <p className="feature-card-text">-  </p>
                        <h6>-</h6>
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <p className="feature-card-text">APY </p>
                        <h5>16.3%</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-start">
                  <div className="roadmap-item mx-2">
                    <div className="roadmap-content">
                      <h4 className="title d-flex align-items-center">
                        <img
                          src="assets/img/solana.png"
                          alt="icon"
                          height={50}
                        />{" "}
                        SOL
                      </h4>
                      <div className="d-flex justify-content-between">
                        <p className="feature-card-text">-  </p>
                        <h6>-</h6>
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <p className="feature-card-text">APY </p>
                        <h5>18.3%</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-start">
                  <div className="roadmap-item mx-2">
                    <div className="roadmap-content">
                      <h4 className="title d-flex align-items-center">
                        <img src="assets/img/avax.png" alt="icon" height={50} />{" "}
                        AVAX
                      </h4>
                      <div className="d-flex justify-content-between">
                        <p className="feature-card-text">-  </p>
                        <h6>-</h6>
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <p className="feature-card-text">APY </p>
                        <h5>17.2%</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-start">
                  <div className="roadmap-item mx-2">
                    <div className="roadmap-content">
                      <h4 className="title d-flex align-items-center">
                        <img src="assets/img/bsw.png" alt="icon" height={50} />{" "}
                        BSW
                      </h4>
                      <div className="d-flex justify-content-between">
                        <p className="feature-card-text">-  </p>
                        <h6>-</h6>
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <p className="feature-card-text">APY</p>
                        <h5>8.2% </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-start">
                  <div className="roadmap-item mx-2">
                    <div className="roadmap-content">
                      <h4 className="title d-flex align-items-center">
                        <img src="assets/img/bnb.png" alt="icon" height={50} />{" "}
                        BNB
                      </h4>
                      <div className="d-flex justify-content-between">
                        <p className="feature-card-text">-  </p>
                        <h6>- </h6>
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <p className="feature-card-text">APY</p>
                        <h5>14.3%</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-start">
                  <div className="roadmap-item mx-2">
                    <div className="roadmap-content">
                      <h4 className="title d-flex align-items-center">
                        <img src="assets/img/cake.png" alt="icon" height={50} />{" "}
                        CAKE
                      </h4>
                      <div className="d-flex justify-content-between">
                        <p className="feature-card-text">-  </p>
                        <h6>-</h6>
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <p className="feature-card-text">APY </p>
                        <h5>22.2%</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-start">
                  <div className="roadmap-item mx-2">
                    <div className="roadmap-content">
                      <h4 className="title d-flex align-items-center">
                        <img src="assets/img/dydx.png" alt="icon" height={50} />{" "}
                        DYDX
                      </h4>
                      <div className="d-flex justify-content-between">
                        <p className="feature-card-text">-  </p>
                        <h6>-</h6>
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <p className="feature-card-text">APY </p>
                        <h5>9.7%</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-start">
                  <div className="roadmap-item mx-2">
                    <div className="roadmap-content">
                      <h4 className="title d-flex align-items-center">
                        <img src="assets/img/dot.png" alt="icon" height={50} />{" "}
                        DOT
                      </h4>
                      <div className="d-flex justify-content-between">
                        <p className="feature-card-text">-  </p>
                        <h6>-</h6>
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <p className="feature-card-text">APY </p>
                        <h5>11.9%</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-start">
                  <div className="roadmap-item mx-2">
                    <div className="roadmap-content">
                      <h4 className="title d-flex align-items-center">
                        <img src="assets/img/ftm.png" alt="icon" height={50} />{" "}
                        FTM
                      </h4>
                      <div className="d-flex justify-content-between">
                        <p className="feature-card-text">-  </p>
                        <h6>-</h6>
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <p className="feature-card-text">APY </p>
                        <h5>19.7%</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-start">
                  <div className="roadmap-item mx-2">
                    <div className="roadmap-content">
                      <h4 className="title d-flex align-items-center">
                        <img
                          src="assets/img/matic.png"
                          alt="icon"
                          height={50}
                        />{" "}
                        MATIC
                      </h4>
                      <div className="d-flex justify-content-between">
                        <p className="feature-card-text">-  </p>
                        <h6>-</h6>
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <p className="feature-card-text">APY </p>
                        <h5>22.8%</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-start">
                  <div className="roadmap-item mx-2">
                    <div className="roadmap-content">
                      <h4 className="title d-flex align-items-center">
                        <img src="assets/img/near.png" alt="icon" height={50} />{" "}
                        NEAR
                      </h4>
                      <div className="d-flex justify-content-between">
                        <p className="feature-card-text">-  </p>
                        <h6>-</h6>
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <p className="feature-card-text">APY </p>
                        <h5>16.1%</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-start">
                  <div className="roadmap-item mx-2">
                    <div className="roadmap-content">
                      <h4 className="title d-flex align-items-center">
                        <img
                          src="assets/img/airnft.png"
                          alt="icon"
                          height={50}
                        />{" "}
                        AIRNFT
                      </h4>
                      <div className="d-flex justify-content-between">
                        <p className="feature-card-text">-  </p>
                        <h6>-</h6>
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <p className="feature-card-text">APY </p>
                        <h5>23.6%</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
