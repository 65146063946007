import React from "react";

export default function GovernanceHero() {
  return (
    <div>
      <div className="wcu-area-1 pt-140 pb-140 position-relative" id="feature">
        <div className="bg-gradient-1">
          <img src="assets/img/update/bg/bg-gradient1-1.jpg" alt="img" />
        </div>
        <div className="container">
          <div className="mb-25">
            <h1 class=" text-new">Governanace</h1>
          </div>
          <div className="text-start mt-50">
            <p class="sec-text  text-white">
              The time lock smart contract will become the owner of all smart
              contracts in the Onmax protocol. This will remove the “admin keys”
              from all contracts and make it only possible to perform any
              powerful action and upgrade via governance. The time lock ensures
              that if a proposal passes, the call data is executed after a
              certain time. The time lock can call any function on any contract
              through an arbitrary data call.
            </p>
            <p class="sec-text text-white mt-30  ">
              We will set up the main governance module on Binance Smart Chain
              (BEP20) , but as needed, additional deployments will be made on
              each EVM chain where Binance Smart Chain (BEP20) operates.{" "}
            </p>
            <p class="sec-text  text-white mt-30">
              The Onmax Security module will be a fork of the already audited
              Compound Protocol smart contract, which is already battle-tested
              on the Binance Smart Chain main net(BEP20) .
            </p>
            <p class="sec-text  text-white mt-30">
              This battle-tested solution of time lock and governance will
              ensure that ONMAX will evolve into a community ($Onmax holder)
              owned protocol without any need to trust a third party.
            </p>
            <p class="sec-text  text-white mt-30">
              Certainly, having the ability to withdraw funds even if a website
              is shut down or inaccessible is crucial for financial security. In
              many cases, when a website or platform closes, there are a few
              scenarios where users might still be able to access their funds:
            </p>
            <h5 class="mt-30 text-new">Backup Harvest  Methods: </h5>
            <p class="sec-text  mt-0 text-white">
              Some platforms allow users to set up backup Harvest  methods.
              This could include direct bank transfers, alternative payment
              systems like PayPal, or providing a wallet address for
              cryptocurrencies. Even if the website shuts down, users can access
              their funds through these alternative methods.
            </p>
            <h5 class="mt-30  text-new">
              Escrow Services or Third-Party Custodians:{" "}
            </h5>
            <p class="sec-text mt-0 text-white">
              In certain cases, especially with financial institutions or larger
              platforms, an escrow service or third-party custodian might hold
              funds separately from the operating company. This ensures that
              even if the website ceases operations, users can reclaim their
              funds directly from this custodian.
            </p>
            <h5 class="mt-30 text-new">Legal Protections and Regulations:</h5>
            <p class="sec-text mt-0  text-white">
              Depending on the regulatory framework, there might be legal
              provisions in place to protect users' funds. This could involve
              mandatory insurance, segregated accounts, or other measures to
              safeguard users' assets in the event of a platform closure.
            </p>
            <h5 class="mt-30 text-new">Communication and Notifications:</h5>
            <p class="sec-text mt-0 text-white">
              Reputable platforms typically notify users well in advance of any
              closure or change in operations. This allows users to take
              necessary actions such as withdrawing funds or transferring them
              to a different account or platform.
            </p>
            <p class="sec-text  text-white mt-30">
              However, it's important to note that not all platforms or
              situations may offer such protections. Users should be cautious
              and research the terms and conditions of any financial platform
              they use, as well as understand the risks involved in case of
              closure or shutdown. Creating backups, keeping track of
              communication from the platform, and understanding Harvest 
              procedures can all help safeguard funds in such situations.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
