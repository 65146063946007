import React from 'react'

export default function Main() {
  return (
    <div>
        <div className="wcu-area-1 pt-130 pb-140 position-relative" id="feature">
  <div className="bg-gradient-1">
    <img src="assets/img/update/bg/bg-gradient1-1.jpg" alt="img" />
  </div>
  <div className="container">
    <div className="mb-25">
      <div className="row gy-5">
        <div className="col-lg-7">
          <div className="section-title mb-0 text-start">
            <h2 className="title style2 ">Farm your favorite crypto assets at flexible or fixed terms!
            </h2>
            <p className="sec-text">Choose our tailored plans to Farm & lock your assets at Onmax while enjoying <br /> astonishing rewards in return. 
            </p>
          </div>
        </div>
        <div className="col-lg-5 ">
          {/* wcu-thumb */}
          <div className=" text-center alltuchtopdown">
            <img src="assets/img/stake-your-favorite-crypto-assets.png" alt="Onmax Token" />
          </div>
        </div>
      </div>
    </div>
    <div className="row gy-5 justify-content-between text-start">
      <div className="col-lg-6">
      <div className="feature-card">
          <div className="feature-card-icon">
            <img src="assets/img/flexible_staking.svg" alt="Flexible Staking" height={45} />
          </div>
          <div className="feature-card-details">
            <h4 className="feature-card-title">Flexible Farming</h4>
            <p className="feature-card-text">Farm assets for flexible terms & unfarm  it anytime.
            </p>
            <h6 className="feature-card-text">Up to 12% APY</h6>
          </div>
        </div>
      </div>
      <div className="col-lg-6">
      <div className="feature-card">
          <div className="feature-card-icon">
            <img src="assets/img/fixed_term_staking.svg" alt="Fixed-term Staking" height={38}/>
          </div>
          <div className="feature-card-details">
            <h4 className="feature-card-title">Fixed-term Farming</h4>
            <p className="feature-card-text">Farm assets for fixed-term with unlock at maturity.
            </p>
            <h6 className="feature-card-text">Up to 40% APY</h6>
          </div>
        </div>
      </div>
    
    </div>
  </div>
</div>

    </div>
  )
}
