import React from 'react'

export default function Hero() {
  return (
    <div>
   
   <div className="hero-wrapper hero-1 text-start">
  <div className="hero-bg-gradient">
  </div>
  <div className="ripple-shape">
    <span className="ripple-1" />
    <span className="ripple-2" />
    <span className="ripple-3" />
    <span className="ripple-4" />
    <span className="ripple-5" />
  </div>
  <div className="container">
    <div className="hero-style1">
      <div className="row flex-row-reverse align-items-center">
        <div className="col-lg-5">
          <div className="hero-thumb alltuchtopdown">
            <img src="assets/img/onmax-hero.png" alt="Onmax Staking" />
          </div>
        </div>
        <div className="col-lg-7">
          <h1 className="hero-title">Earn Standard Farming Yields Up To 40%
          </h1>
          <h6 className='ms-2 mt-2'>Earn highest Farming yields at Onmax in a safe, secure & risk-free way. </h6>
          <div className="btn-wrap">
            <a  href="https://app.onmaxyield.farm/" target='_blank'className="btn btn2">
            Farm Now
            </a>
            <a href="https://onmax.gitbook.io/docs-onmax" target='_blanck' className="btn btn-two">
            Docs 
            </a>
          </div>
        </div>
      </div>
    </div>
   {/* onmaxyield.farm */} 
   {/* app.onmaxyield.farm */}
   {/* Farming */}
  </div>
</div>

    </div>
  )
}
