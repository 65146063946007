import React from 'react'
import Header from '../Coman/Header'
import GovernanceHero from './GovernanceHero'
import Footer from '../Coman/Footer'

export default function Governance() {
  return (
    <div>
         <div className="home-purple-gradient">
            <Header/>
            <GovernanceHero/>
            <Footer/>
         </div>
    </div>
  )
}
